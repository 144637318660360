import React, { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Uvod() {
  const { language, setlanguage } = useContext(LanguageContext);
  
  return (
    <div className="grid">
      
      <header className="header">
      <Header Value= {5}/>
      </header>
      
      <main className="main">
        <h1>{language === 'en' ? 'Price' : 'Cena'}</h1>

      <table>
  <tr>
    <th> {language === 'en' ? 'Month' : 'Měsíc'}</th>
    <th>{language === 'en' ? 'price per night' : 'cena za noc'}</th>
  </tr>
  <tr>
    <td>{language === 'en' ? 'NOVEMBER-APRIL' : 'LISTOPAD-DUBEN'}</td>
    <td>{language === 'en' ? '65 EUR/1600 Czk' : '65 EUR/ 1600 Kč'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'MAY + OCTOBER' : 'KVĚTEN + ŘÍJEN'} </td>
    <td>{language === 'en' ? '85 EUR/2100 Czk' : '85 EUR/ 2100 Kč'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'JUNE ' : 'ČERVEN'}</td>
    <td>{language === 'en' ? '110 EUR/2750 Czk' : '110 EUR/2750 Kč'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? ' SEMPTEMBER' : 'ZÁŘÍ'}</td>
    <td>{language === 'en' ? '100 EUR/2500 Czk' : '100 EUR/2500 Kč'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'JULY + AUGUST' : 'ČERVENEC + SRPEN'}</td>
    <td>{language === 'en' ? '140 EUR/3350 Czk' : '140 EUR/3350 Kč'}</td>
  </tr>
</table> 

<table>
  <tr>
    <th>{language === 'en' ? 'mandatory surcharge' : 'Povinné příplatky'}</th>
    <th>{language === 'en' ? 'price/night' : 'cena/noc'}</th>
  </tr>
  <tr>
    <td>{language === 'en' ? 'end of stay cleaning' : 'Závěrečný úklid'}</td>
    <td>{language === 'en' ? '70 EUR/1750 Czk' : '70 EUR/1750 Kč'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'The price includes 4 people. Additional person from age 3 and upwards' : 'V ceně jsou 4 osoby. Další osoba od 3 let výše'}</td>
    <td>{language === 'en' ? '10 EUR(250 Czk)/night' : '10 EUR(250 Kč)/noc'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Turistic tax' : 'Turistická taxa'}</td>
    <td>{language === 'en' ? 'paid on the spot' : 'platí se na místě'}</td>
  </tr>
</table> 
<table>
  <tr>
    <th>{language === 'en' ? 'Included in price' : 'V ceně nájmu jsou'}</th>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Bedding, bath towels, towels (only for apartment use, not beach towels)' : 'Lůžkoviny, osušky, ručníky (pouze na použití v apartmánu, nikoli osušky na pláž)'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Parking in front of the apartment' : 'Parkování před domem'}</td>
  </tr>
  <tr>
    <td> {language === 'en' ? 'Utilities (water, electricity)' : 'Veškeré energie (voda, elektřina)'} </td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Wifi' : 'Wifi'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Air conditioning' : 'Klimatizace'} </td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Electric grill' : 'Elektrický gril'}</td>
  </tr>
  <tr>
    <td>{language === 'en' ? 'Board games' : 'Společenské hry'}</td>
  </tr>
</table> 

    <h2>{language === 'en' ? 'Possibility of beer tap rental for 1000 Czk/stay' : 'Možnost pronájmu pípy za 1000 Kč/pobyt'}</h2>
    <h2>{language === 'en' ? 'During main season the minimal stay duration is 7 nights' : 'Minimální délka pobytu v hlavní sezóně je 7 nocí'}</h2>
  <h2>{language === 'en' ? 'Check-in: 16:00, check-out: 10:00' : 'Check-in: 16:00, check-out: 10:00'}</h2>

  <h2 className="long">{language === 'en' ? 'Method of payment for accommodation' : 'Způsob úhrady ceny za ubytování:'}</h2>
<h2 className="long">a){language === 'en' ? 'reservation deposit is 30% of the accommodation price, which is to be paid within 5 days of booking the accommodation on the provider’s website.' : ' rezervační záloha ve výši 30% z ceny ubytování, která je splatná do 5 dnů od rezervace ubytování na webových stránkách poskytovatele.'} </h2>
<h2 className="long">b) {language === 'en' ? 'the remainder of the total price of accomodation is to be paid no later than 21 days before the agreed arrival at the accommodation' : 'zbylá část celkové ceny za ubytování po odečtení rezervační zálohy je splatná nejpozději do 21 dnů před sjednaným nástupem na ubytování.'}</h2>

      </main>

      <footer className="footer">
        <Footer/>
      </footer>

</div>
);
}

export default Uvod;
